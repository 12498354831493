/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/bvger-etias/etias-testing/-/issues/186
 *
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Utils } from "./Utils";
import constVar from "../../angular/src/assets/const.json";
import { translationCurrent } from "./languages/LanguageFormat";
import { handleFileUploadDefault } from "./BrowserUpload/AutoUpload2";
import { LoggerFactory } from "myd-commons";
import { getFilesFromDrag } from "./BrowserUpload/DragAndDropHandler";
export class CustomUploadBtn {
    static applyChangesToCustomBrowse(inpStylId, inpStylIdSpan) {
        let fileStatus = Utils.getElementInput(inpStylIdSpan);
        let fileInput = Utils.getElementInput(inpStylId);
        if (!fileInput)
            return;
        if (!fileInput.files)
            return;
        if (!fileStatus)
            return;
        let fileLng = fileInput.files.length;
        if (fileLng === 1) {
            fileStatus.textContent = "";
        }
        else if (fileLng > 1) {
            fileStatus.textContent =
                fileInput.files.length +
                    " " +
                    translationCurrent.ts_filesSelected;
        }
        else {
            fileStatus.textContent =
                translationCurrent.sd_noFilesSelected;
        }
    }
    static dragOverHandlerFiles(ev) {
        ev.preventDefault();
    }
    static dropHandlerFiles(ev, inpID) {
        return __awaiter(this, void 0, void 0, function* () {
            ev.preventDefault();
            if (!ev.dataTransfer)
                return;
            let files = yield getFilesFromDrag(ev);
            let inp = Utils.getElementInput(inpID);
            if (!inp)
                return;
            inp.files = files;
            handleFileUploadDefault(inp);
            let inpDiv = Utils.getElementInput(inpID + "r");
            if (!inpDiv)
                return;
            inpDiv.classList.remove('ondragOver');
        });
    }
    static setUploadFieldToErr(inputID) {
        let span = Utils.getElementInput(inputID + "Span");
        let btn = Utils.getElementInput(inputID + CustomUploadBtn.browseBtn);
        if (!span || !btn)
            return;
        span.classList.add(constVar["gc_ERR_CSS_CLASS"]);
        btn.classList.add(constVar["gc_ERR_CSS_CLASS"]);
    }
    static removeUploadFieldErr(inputID) {
        let span = Utils.getElementInput(inputID + "Span");
        let btn = Utils.getElementInput(inputID + CustomUploadBtn.browseBtn);
        if (!span || !btn)
            return;
        span.classList.remove(constVar["gc_ERR_CSS_CLASS"]);
        btn.classList.remove(constVar["gc_ERR_CSS_CLASS"]);
    }
    static validateFileInputs(event) {
        let valid = true;
        const fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach((input) => {
            var _a;
            const inputFile = input;
            if (inputFile && inputFile.required && ((_a = inputFile.files) === null || _a === void 0 ? void 0 : _a.length) === 0) {
                CustomUploadBtn.setUploadFieldToErr(inputFile.id);
                event.preventDefault();
                valid = false;
            }
        });
        return valid;
    }
    static clickListenerFormSubmit() {
        let contaFrmSnd = Utils.getContaFrmSnd();
        if (contaFrmSnd) {
            contaFrmSnd.addEventListener("click", (event) => {
                CustomUploadBtn.validateFileInputs(event);
            });
        }
    }
    static convertInputErrors() {
        const fileInputs = document.querySelectorAll('input[type="file"]');
        fileInputs.forEach((input) => {
            const inputFile = input;
            if (inputFile && inputFile.classList.contains(constVar["gc_ERR_CSS_CLASS"])) {
                CustomUploadBtn.setUploadFieldToErr(inputFile.id);
            }
        });
    }
    static clickInput(ID) {
        let fileInput = document.getElementById(ID);
        if (!fileInput) {
            CustomUploadBtn.logger.error(`"${ID}"` + " file input was not found. Please update the sqi.");
            return;
        }
        if (!fileInput.onchange) {
            CustomUploadBtn.logger.warn(`"${ID}"` + " file input does not have 'onClick=`handleFileUploadDefault(this);`. Please update the sqi.");
            fileInput.onchange = (event) => handleFileUploadDefault(event.target);
        }
        fileInput.click();
    }
}
CustomUploadBtn.logger = LoggerFactory.getLogger(CustomUploadBtn.name);
CustomUploadBtn.browseBtn = "BrowseBtn";
