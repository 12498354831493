/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVar from "../../../angular/src/assets/i18n/it.json";
export const itTranslation = {
    ts_Delete: "Elimina",
    ts_CheckAttaBeforeDispatch: "Verifica l’allegato prima dell’invio",
    ts_TooBig: "Troppo grande",
    ts_attachment: "allegato",
    ts_available: "disponibile",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "invece di",
    ts_moreInfo: "Visualizza maggiori informazioni",
    ts_in_aNewWindow: "in una nuova finestra",
    ts_MoreThanOneNumberGroupPresent: "È presente più di un gruppo di numeri.",
    ts_NoNumberPresent: "Nessun numero è presente.",
    ts_TooManyDecimalDelimiters: "Troppi delimitatori decimali.",
    ts_TooManyMinusSigns: "Troppi segni meno.",
    ts_LeadingZerosRemoved: "Zeri iniziali rimossi.",
    ts_WhitespaceRemoved: "Spazi vuoti rimossi.",
    ts_CommasReplacedWithDots: "Virgole sostituite con punti.",
    ts_MultipleMinusSignsReducedToOne: "Più segni meno ridotti a uno.",
    ts_MultipleDecimalDelimitersReducedToOne: "Più delimitatori decimali ridotti a solo",
    ts_MultipleCharsReducedToOne: "Varie lettere consecutive ridotte a:",
    ts_NonNumberRemoved: "Caratteri non numerici rimossi (eccetto - e . ).",
    ts_NumberRounded: "Il numero è stato arrotondato.",
    ts_FailedRoundingTheNumber: "Arrotondamento del numero non riuscito.",
    ts_MinusSignNotAllowed: "Il segno meno non è consentito ed è stato rimosso.",
    ts_QuoteRemoved: "Virgolette ('\") rimosse.",
    ts_Input: "Input",
    ts_IsInvalid: "non è valido",
    ts_showFiles: "Mostra file",
    ts_hideFiles: "Nascondi file",
    ts_deleteAllFiles: "Elimina tutti i file",
    sd_noFilesSelected: constVar["sd_noFilesSelected"],
    ts_filesSelected: "file da caricare",
    ts_onlyOneFileAllowed: "È consentito solo un file per questo input",
    sd_From: constVar["sd_From"],
    sd_to: constVar["sd_to"],
    ts_correctGroups: "Rilevati più input di gruppo corretti, scegli uno: ",
    ts_incorrectCharacter: "Carattere errato rilevato: ",
    ts_inputTooLong: "L'input contiene troppi caratteri. Massimo consentito:",
    ts_currently: "Attualmente: ",
    ts_inputTooShort: "L'input contiene troppo pochi caratteri. Minimo richiesto:",
    ts_outOfRangeDate: "La data inserita è fuori dall'intervallo.",
    ts_outOfRangeTooEarly: "è troppo tardi quando il massimo consentito è",
    ts_outOfRangeTooLate: "è troppo presto quando il minimo consentito è",
    ts_noValidDateFound: "La data inserita non è valida.",
    ts_dateChangedTo: "non è una data valida ed è stata cambiata in",
    ts_dateChangedFrom: "La data inserita è stata cambiata da",
    ts_unsupportedDateFormat: "Formato data non supportato",
    ts_parameter: "Parametro",
    sd_Month: constVar["sd_Month"],
    sd_Year: constVar["sd_Year"],
    sd_day: constVar["sd_day"],
    ts_pleaseChange: "Si prega di cambiare",
    ts_toMatchTheFormat: "per corrispondere al formato corretto",
    ts_whitespacesRemoved: "spazi vuoti rimossi",
    ts_whitespaceRemoved: "spazio vuoto rimossi",
    ts_wrongSeparators: "sono separatori errati, il formato corretto è",
    ts_dateFormatChangedTo: "Formato data cambiato in",
    sd_of: constVar["sd_of"],
    sd_moreInfo: `${constVar["sd_moreIn"]} (${constVar["sd_in_aNewWindow"]})`,
    sd_orLess: constVar["sd_orLess"],
    sd_interoperable: constVar["sd_interoperable"],
    sd_registeredTitlePrefix: constVar["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVar["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "rimuovi caratteri non-ascii e speciali iniziali e finali",
    ts_emailmusthaveat: "Un " + constVar["sd_Email_ad"] + " deve avere un \"\@\" !",
    ts_emailmusthavedomain: "Un " + constVar["sd_Email_ad"] + " deve terminare con \"domain.topLevelDomain\" ad esempio \"ti.ch\" !",
    ts_wrongEmailSeparators: constVar["sd_emailEntryTooltip"] + " - " + constVar["sd_not"] + " \";\" (" + constVar["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "È consentito solo un " + constVar["sd_Email_ad"] + " !",
    ts_extraDotRemovedfromAdjacenttoAt: "il punto extra viene rimosso per adiacente a \"\@\" !",
    ts_invalidAHVNummerRemoved: "Il Numero AVS non valido è stato rimosso!",
    ts_nonMatchingCharsRemoved: "lettere invalidi retirati!",
    sd_Country: constVar["sd_Country"],
    sd_mobileNrForQesAuthoriz: constVar["sd_mobileNrForQesAuthoriz"],
    sd_GivenName: constVar["sd_GivenName"],
    sd_Lastname: constVar["sd_Lastname"],
    sd_SubmitTxt: constVar["sd_SubmitTxt"],
    ts_phoneNumber: "Numero di telefono",
    ts_enterQESInfo: "Inserisci le informazioni QES",
    ts_defaultError: "Qualcosa è andato storto, per favore riprova",
    ts_fillInAllFields: "Si prega di compilare tutti i campi",
    ts_phoneNumberError: "Assicurati che il numero di telefono sia corretto",
    ts_emailError: "Assicurati che l'email sia corretta",
    ts_qesSubmittSuccess: "Le informazioni QES sono state inviate con successo",
    ts_qesEmailNotFound: "La tua email non è stata trovata nel sistema, si prega di inserire il nome e il cognome",
    ts_qesError: "Impossibile aggiornare le informazioni QES",
    ts_because: "perché",
    ts_qesErrorSession: "Impossibile verificare la sessione QES",
    ts_success: "Successo",
    ts_countryCodeError: "Il codice del paese ricevuto è errato",
    ts_qesNameNotFound: "Il nome o il cognome non possono essere vuoti",
    ts_longWaitTimeTitle: "Tempi di attesa più lunghi del solito",
    ts_longWaitTimeText1: "Ci scusiamo per i lunghi tempi di attesa! Purtroppo l'elaborazione della tua richiesta sta richiedendo più tempo del previsto. È possibile che la tua richiesta sia già stata elaborata e inviata al destinatario corretto. In questo caso, controlla se hai ricevuto una ricevuta di consegna da PrivaSphere. Questa ricevuta di invio costituisce una conferma legale che il modulo è stato inviato.",
    ts_longWaitTimeText2: "Se hai ricevuto una ricevuta di deposito ma la pagina del modulo mostra ancora che è in stato di invio, ti preghiamo di segnalarci questo errore tramite: ",
    ts_includeLogs: "Si prega di scaricare i log e includerli nell'email come allegato.",
    ts_downloadLogs: "Scarica Log",
    ts_inputNumber: "Numero di input",
    ts_inputAmendedWithProperDots: "L'input è stato modificato con i punti corretti"
};
