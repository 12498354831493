/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * (cd ~/PrivaLope; ant ts-compile 2>&1 | tee ant`date +%y%m%d_%H%M%S`.txt)
 *
 * (cd ~/PrivaLope; ant ngI18n ts-lab 2>&1 | tee ant`date +%y%m%d_%H%M%S`.txt)
 *
 * possibly,
 *    (cd ~/PrivaLope; ant buildAngular 2>&1 | tee ant`date +%y%m%d_%H%M%S`.txt)
 * is needed beforehand
 *
 * this creates js/bundle.js
 *
 * myd-commons is needed to compile this project, make sure that you have access to https://git.my-d.org/rolborsos/myd-commons/
 * and follow inctructions described in https://git.my-d.org/rolborsos/myd-commons/-/tree/main#install
 *
 */
import { handleFileUploadDefault, handleFileUploadSetAllowedExtensionsAndSize, handleFileUploadSetBlockedExtensions, handleFileUploadSetAllowedExtensions, handleFileUpload, handleCreateprivalopeFileUpload, getElementIdByFile, getForceLargeUploadOfSmallFiles, showLargeFiles, } from "./BrowserUpload/AutoUpload2";
import { allFilesToBigErrMsg, extInList, extNotAllowedErrMsg, fileTooBigErrMsg, getFileExtension, humanFileSiz, isAnyFileLargerThan15MB } from "./BrowserUpload/utilsFile";
import { copyDropDownTextToField, setDropdownOnPageLoad, updateTimeOnSubmission } from "./FormsUtils";
import { CustomUploadBtn } from "./CustomUploadBtn";
import { preprocessDateInputsISO8601, processDateBeforeSubmit, processParamDate } from "./DatePickerUtils";
import { validateEmailInput } from "./FieldValidators/EmailValidator";
import { getFilesFromDrag } from "./BrowserUpload/DragAndDropHandler";
window.handleFileUploadDefault = handleFileUploadDefault;
window.handleFileUploadSetAllowedExtensionsAndSize = handleFileUploadSetAllowedExtensionsAndSize;
window.handleFileUploadSetBlockedExtensions = handleFileUploadSetBlockedExtensions;
window.handleFileUploadSetAllowedExtensions = handleFileUploadSetAllowedExtensions;
window.handleFileUpload = handleFileUpload;
window.humanFileSiz = humanFileSiz;
window.extInList = extInList;
window.getFileExtension = getFileExtension;
window.fileTooBigErrMsg = fileTooBigErrMsg;
window.allFilesToBigErrMsg = allFilesToBigErrMsg;
window.extNotAllowedErrMsg = extNotAllowedErrMsg;
window.handleCreateprivalopeFileUpload = handleCreateprivalopeFileUpload;
window.getElementIdByFile = getElementIdByFile;
window.getForceLargeUploadOfSmallFiles = getForceLargeUploadOfSmallFiles;
window.showLargeFiles = showLargeFiles;
window.isAnyFileLargerThan15MB = isAnyFileLargerThan15MB;
window.dragOverHandlerFiles = CustomUploadBtn.dragOverHandlerFiles;
window.dropHandlerFiles = CustomUploadBtn.dropHandlerFiles;
window.clickInput = CustomUploadBtn.clickInput;
window.copyDropDownTextToField = copyDropDownTextToField;
window.setDropdownOnPageLoad = setDropdownOnPageLoad;
window.preprocessDateInputsISO8601 = preprocessDateInputsISO8601;
window.processDateBeforeSubmit = processDateBeforeSubmit;
window.processParamDate = processParamDate;
window.validateEmailInput = validateEmailInput;
window.updateTimeOnSubmission = updateTimeOnSubmission;
window.getFilesFromDrag = getFilesFromDrag;
console.log(`-------------------------------------`);
console.log(`Privalope TypeScript compiled at: ${process.env.BUILD_DATE}`);
console.log(`Commit hash: ${process.env.COMMIT_HASH}`);
console.log(`Commit message: ${process.env.COMMIT_MESSAGE}`);
console.log(`-------------------------------------`);
