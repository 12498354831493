/// <reference types="filesystem" />
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 *
 * https://git.privasphere.com/privasphere/privalope/-/issues/159
 *
 */
export function getFilesFromDrag(event) {
    var _a;
    return __awaiter(this, void 0, void 0, function* () {
        const items = (_a = event.dataTransfer) === null || _a === void 0 ? void 0 : _a.items;
        if (!items) {
            console.error("No items found in the drag-and-drop event.");
            return new DataTransfer().files; // Return an empty FileList
        }
        const fileList = [];
        const promises = [];
        for (let i = 0; i < items.length; i++) {
            const entry = items[i].webkitGetAsEntry();
            if (entry) {
                if (entry.isFile) {
                    // Handle regular files
                    const file = items[i].getAsFile();
                    if (file)
                        fileList.push(file);
                }
                else if (entry.isDirectory) {
                    const promise = readFolder(entry).then(filesInFolder => {
                        fileList.push(...filesInFolder);
                    });
                    promises.push(promise);
                }
            }
        }
        try {
            yield Promise.all(promises);
            const dataTransfer = new DataTransfer();
            fileList.forEach((file) => dataTransfer.items.add(file));
            return dataTransfer.files;
        }
        catch (error) {
            console.error("Error reading files from folder:", error);
            return new DataTransfer().files; // Return an empty FileList in case of error
        }
    });
}
function getFile(entry) {
    return new Promise((resolve, reject) => {
        entry.file(resolve, reject);
    });
}
function readFolder(directoryEntry) {
    return new Promise((resolve, reject) => {
        const reader = directoryEntry.createReader();
        const files = [];
        const readEntries = () => {
            reader.readEntries((entries) => __awaiter(this, void 0, void 0, function* () {
                if (entries.length === 0) {
                    resolve(files);
                }
                else {
                    for (const entry of entries) {
                        if (entry.isFile) {
                            const file = yield getFile(entry);
                            if (file)
                                files.push(file);
                        }
                        else if (entry.isDirectory) {
                            const nestedFiles = yield readFolder(entry);
                            files.push(...nestedFiles);
                        }
                    }
                    readEntries();
                }
            }), reject);
        };
        readEntries();
    });
}
