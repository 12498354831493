/**
 * @author gudiskis PrivaSphere confidential, (c) 2024 - 2024 all rights reserved
 */
import { LoggerFactory } from "myd-commons";
import { Validator } from "./Validator";
import constVar from "../../../angular/src/assets/const.json";
import { translationCurrent } from "../languages/LanguageFormat";
export var dateLogs = {};
export var currDateLogs = {};
export class DateValidator extends Validator {
    constructor() {
        super();
        this.showMainError = this.showMainError.bind(this);
        this.showMainNotif = this.showMainNotif.bind(this);
        this.logger = LoggerFactory.getLogger(DateValidator.name);
    }
    showMainNotif() {
        const notif = this.parser.getNotificationByProperty("date.formatted");
        this.showMainNotifInfo(notif.err);
    }
    showNotif(infoMsg) {
        this.showNotifInfo(infoMsg, this.showMainNotif);
        // Hide notif after 10 seconds
        setTimeout(() => {
            var _a, _b, _c;
            const infoMessage = (_b = (_a = this.inputField) === null || _a === void 0 ? void 0 : _a.parentNode) === null || _b === void 0 ? void 0 : _b.querySelector("#" + this.notifContainerID + ((_c = this.inputField) === null || _c === void 0 ? void 0 : _c.id));
            if (infoMessage) {
                this.removeNotifMessage();
            }
        }, 10000);
    }
    showError(errMsg) {
        this.showErrorInfo(errMsg, this.showMainError);
        this.eventTracker.error.push({
            message: errMsg,
            log: errMsg
        });
    }
    showMainError() {
        const error = this.parser.getErrorByProperty("wrong.date.entry");
        this.showMainErrorInfo((error === null || error === void 0 ? void 0 : error.err) + this.getDateFormat(), (error === null || error === void 0 ? void 0 : error.property) + " " + (error === null || error === void 0 ? void 0 : error.constant), error === null || error === void 0 ? void 0 : error.errNo);
    }
    getDateFormat() {
        switch (currentLanguageTS) {
            case "en":
                return constVar["EN_DATE_FORMAT"];
            case "de":
                return constVar["DE_DATE_FORMAT"];
            case "fr":
                return constVar["FR_DATE_FORMAT"];
            case "it":
                return constVar["IT_DATE_FORMAT"];
            default:
                return constVar["EN_DATE_FORMAT"];
        }
    }
    getKeysDateLogsOfElement(elementID) {
        if (!dateLogs[elementID])
            return Object.keys({});
        return Object.keys(dateLogs[elementID]);
    }
    static addLogToDateLogs(elementID, inputChange, original, newChange, userDate) {
        if (!dateLogs[elementID]) {
            dateLogs[elementID] = {};
        }
        if ("remove" == inputChange || "remove" == newChange) {
            delete dateLogs[elementID][inputChange];
            return dateLogs;
        }
        if (inputChange != DateValidator.noValidDateFound && inputChange != DateValidator.outOfRangeTooLate && inputChange != DateValidator.outOfRangeTooEarly) {
            DateValidator.removeAllErrorLogs(elementID);
        }
        dateLogs[elementID][inputChange] = [original, newChange, userDate];
        currDateLogs[elementID] = userDate;
        return dateLogs;
    }
    static removeAllErrorLogs(elementID) {
        delete dateLogs[elementID][DateValidator.noValidDateFound];
        delete dateLogs[elementID][DateValidator.outOfRangeTooLate];
        delete dateLogs[elementID][DateValidator.outOfRangeTooEarly];
    }
    static showErrorDateLogs(input) {
        let dateValid = new DateValidator();
        dateValid.initializeValidator(input);
        let elementID = input.id;
        let dateLogsKeys = dateValid.getKeysDateLogsOfElement(elementID);
        let showInfo = !DateValidator.dateLogsContainsErrors(elementID);
        DateValidator.processDateLogsNotif(dateLogsKeys, elementID, dateValid, showInfo);
        DateValidator.processDateLogsError(dateLogsKeys, elementID, dateValid, input);
        dateValid.showAllEventTrackerMessages();
        dateLogs[elementID] = {};
    }
    static dateLogsContainsErrors(elementID) {
        if (!dateLogs)
            return false;
        if (!dateLogs[elementID])
            return false;
        let dateLogsKeys = Object.keys(dateLogs[elementID]);
        for (let i = 0; i < dateLogsKeys.length; i++) {
            let key = dateLogsKeys[i];
            if (key == DateValidator.noValidDateFound || key == DateValidator.outOfRangeTooLate || key == DateValidator.outOfRangeTooEarly) {
                return true;
            }
        }
        return false;
    }
    static processDateLogsError(dateLogsKeys, elementID, dateValid, input) {
        let errorFound = false;
        for (let i = 0; i < dateLogsKeys.length && !errorFound; i++) {
            let key = dateLogsKeys[i];
            let original = dateLogs[elementID][key][0];
            let newChange = dateLogs[elementID][key][1];
            let userDate = currDateLogs[elementID];
            switch (key) {
                case DateValidator.noValidDateFound: {
                    dateValid.showError(`${translationCurrent.ts_noValidDateFound} ${translationCurrent.ts_pleaseChange} "` +
                        userDate + `" ${translationCurrent.ts_toMatchTheFormat}.`);
                    dateValid.logger.warn(`ID: "${elementID}".${DateValidator.getLabelMsg(elementID)} No valid date found. Entered date: "${userDate}".`);
                    input.value = original;
                    errorFound = true;
                    break;
                }
                case DateValidator.outOfRangeTooLate: {
                    dateValid.showError(`${translationCurrent.ts_outOfRangeDate} "`
                        + userDate + `" ${translationCurrent.ts_outOfRangeTooEarly} "` + newChange + "\"");
                    dateValid.logger.warn(`ID: "${elementID}".${DateValidator.getLabelMsg(elementID)} Date out of range. Entered date: "${userDate}". Max allowed date: "${newChange}"`);
                    input.value = original;
                    errorFound = true;
                    break;
                }
                case DateValidator.outOfRangeTooEarly: {
                    dateValid.showError(`${translationCurrent.ts_outOfRangeDate} "`
                        + userDate + `" ${translationCurrent.ts_outOfRangeTooLate} "` + newChange + "\"");
                    dateValid.logger.warn(`ID: "${elementID}".${DateValidator.getLabelMsg(elementID)} Date out of range. Entered date: "${userDate}". Min allowed date: "${newChange}"`);
                    input.value = original;
                    errorFound = true;
                    break;
                }
            }
        }
    }
    static getLabelMsg(elementID) {
        let lblID = elementID.replace(/^\D+/g, '');
        let lableMsg = "";
        let lbl = document.getElementById("lbl" + lblID);
        if (lbl) {
            lableMsg += ` For label: "${lbl.innerText}".`;
        }
        return lableMsg;
    }
    static processDateLogsNotif(dateLogsKeys, elementID, dateValid, showInfo) {
        for (let i = 0; i < dateLogsKeys.length; i++) {
            let key = dateLogsKeys[i];
            let original = dateLogs[elementID][key][0];
            let newChange = dateLogs[elementID][key][1];
            let userDate = dateLogs[elementID][key][2];
            if (key != DateValidator.noValidDateFound && key != DateValidator.outOfRangeTooLate && key != DateValidator.outOfRangeTooEarly) {
                let infoMsg = DateValidator.getKeyAsWord(key, original, dateValid) + " \"" +
                    original + `" ${translationCurrent.sd_to} "` + newChange + "\"";
                if (showInfo) {
                    dateValid.showNotif(infoMsg);
                }
                dateValid.logger.info(`ID: "${elementID}".${DateValidator.getLabelMsg(elementID)} Date changed for ${DateValidator.getKeyAsWord(key, userDate, dateValid)} (${key}) from "${original}" to "${newChange}" when original date was "${userDate}"`);
            }
        }
    }
    static getKeyAsWord(key, userDate, dateValid) {
        switch (key) {
            case "mm":
                return `${translationCurrent.ts_dateChangedFrom} ` + translationCurrent.sd_Month.toLowerCase();
            case "yyyy":
                return `${translationCurrent.ts_dateChangedFrom} ` + translationCurrent.sd_Year.toLowerCase();
            case "dd":
                return `${translationCurrent.ts_dateChangedFrom} ` + translationCurrent.sd_day.toLowerCase();
            case "strippedWhiteSpaces":
                let numOfWhiteSpaces = (userDate.match(/\s/g) || []).length;
                let whiteSpace = "";
                if (numOfWhiteSpaces > 1) {
                    whiteSpace = translationCurrent.ts_whitespacesRemoved;
                }
                else {
                    whiteSpace = translationCurrent.ts_whitespaceRemoved;
                }
                return `${numOfWhiteSpaces} ${whiteSpace} `;
            case "formattedSeparators":
                let invalid = userDate.replace(/\d/g, '');
                if (invalid.length > 0)
                    invalid = invalid[0];
                let dateV = new DateValidator();
                return `${invalid} ${translationCurrent.ts_wrongSeparators} ${dateV.getDateFormat()}`;
            case "formattedDate":
                return `${translationCurrent.ts_dateFormatChangedTo}`;
            default:
                return key;
        }
    }
}
DateValidator.noValidDateFound = "noValidDateFound";
DateValidator.outOfRangeTooLate = "outOfRange_tooLate";
DateValidator.outOfRangeTooEarly = "outOfRange_tooEarly";
