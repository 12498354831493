/**
 * @author gudiskis PrivaSphere confidential, (c) 2023 - 2024 all rights reserved
 */
import constVarLan from "../../../angular/src/assets/i18n/en.json";
export const enTranslation = {
    ts_Delete: "Delete",
    ts_CheckAttaBeforeDispatch: "Attachment-check prior to dispatch",
    ts_TooBig: "Too big",
    ts_attachment: "attachment",
    ts_available: "available",
    ts_cumulated: "cumulated",
    ts_fileNameExtension: "filename-extension",
    ts_instead: "instead",
    ts_moreInfo: "Show more information",
    ts_in_aNewWindow: "in a new Window",
    ts_MoreThanOneNumberGroupPresent: "More than one number group is present.",
    ts_NoNumberPresent: "No number is present.",
    ts_TooManyDecimalDelimiters: "Too many decimal delimiters.",
    ts_TooManyMinusSigns: "Too many minus signs.",
    ts_LeadingZerosRemoved: "Leading zeros have been removed.",
    ts_WhitespaceRemoved: "Whitespace removed.",
    ts_CommasReplacedWithDots: "Commas replaced with dots.",
    ts_MultipleMinusSignsReducedToOne: "Multiple minus signs reduced to one.",
    ts_MultipleDecimalDelimitersReducedToOne: "Multiple decimal delimiters reduced to one.",
    ts_MultipleCharsReducedToOne: "Multiple consecutive characters reduced to only",
    ts_NonNumberRemoved: "Non-number characters removed (except - and . ).",
    ts_NumberRounded: "The number was rounded.",
    ts_FailedRoundingTheNumber: "Failed rounding the number.",
    ts_MinusSignNotAllowed: "Minus sign is not allowed and has been removed.",
    ts_QuoteRemoved: "Quotes ('\"`) removed.",
    ts_Input: "Input",
    ts_IsInvalid: "is invalid",
    ts_showFiles: "Show files",
    ts_hideFiles: "Hide files",
    ts_deleteAllFiles: "Delete all files",
    sd_noFilesSelected: constVarLan["sd_noFilesSelected"],
    ts_filesSelected: "files to be uploaded",
    ts_onlyOneFileAllowed: "Only one file is allowed for this input",
    sd_From: constVarLan["sd_From"],
    sd_to: constVarLan["sd_to"],
    ts_correctGroups: "Multiple correct group inputs detected, pick one: ",
    ts_incorrectCharacter: "Incorrect characters detected: ",
    ts_inputTooLong: "Input contains too many characters. Maximum allowed:",
    ts_currently: "Currently: ",
    ts_inputTooShort: "Input contains too few characters. Minimum required:",
    ts_outOfRangeDate: "The date entered is out of range.",
    ts_outOfRangeTooEarly: "is too late when max allowed is",
    ts_outOfRangeTooLate: "is too early when min allowed is",
    ts_noValidDateFound: "The date entered is not valid.",
    ts_dateChangedTo: "is not a valid date and has been changed to",
    ts_dateChangedFrom: "Date entered has been changed from",
    ts_unsupportedDateFormat: "Unsupported date format",
    ts_parameter: "Parameter",
    sd_Month: constVarLan["sd_Month"],
    sd_Year: constVarLan["sd_Year"],
    sd_day: constVarLan["sd_day"],
    ts_pleaseChange: "Please change",
    ts_toMatchTheFormat: "to match the correct format",
    ts_whitespacesRemoved: "white spaces removed",
    ts_whitespaceRemoved: "whites pace removed",
    ts_wrongSeparators: "is wrong separators, correct format is",
    ts_dateFormatChangedTo: "Date format changed to",
    sd_of: constVarLan["sd_of"],
    sd_moreInfo: `${constVarLan["sd_moreIn"]} (${constVarLan["sd_in_aNewWindow"]})`,
    sd_orLess: constVarLan["sd_orLess"],
    sd_interoperable: constVarLan["sd_interoperable"],
    sd_registeredTitlePrefix: constVarLan["sd_registeredTitlePrefix"],
    sd_PermittedExtensions: constVarLan["sd_PermittedExtensions"],
    ts_leadtrailRemoved: "remove leading and trailing non-ascii & special chars",
    ts_emailmusthaveat: "An " + constVarLan["sd_Email_ad"] + " must have an \"\@\" !",
    ts_emailmusthavedomain: "An " + constVarLan["sd_Email_ad"] + " must end with \"domain.topLevelDomain\" e.g. \"ti.ch\" !",
    ts_wrongEmailSeparators: constVarLan["sd_emailEntryTooltip"] + " - " + constVarLan["sd_not"] + " \";\" (" + constVarLan["sd_semicolon"] + ") !",
    ts_emailmustonlyone: "Only one " + constVarLan["sd_Email_ad"] + " is allowed !",
    ts_extraDotRemovedfromAdjacenttoAt: "extra dot is removed for adjacent to \"\@\" !",
    ts_invalidAHVNummerRemoved: "Invalid AHV-Number is removed!",
    ts_nonMatchingCharsRemoved: "non-matching characters removed!",
    sd_Country: constVarLan["sd_Country"],
    sd_mobileNrForQesAuthoriz: constVarLan["sd_mobileNrForQesAuthoriz"],
    sd_GivenName: constVarLan["sd_GivenName"],
    sd_Lastname: constVarLan["sd_Lastname"],
    sd_SubmitTxt: constVarLan["sd_SubmitTxt"],
    ts_phoneNumber: "Phone number",
    ts_enterQESInfo: "Enter QES Information",
    ts_defaultError: "Something went wrong, please try again",
    ts_fillInAllFields: "Please fill in all fields",
    ts_phoneNumberError: "Make sure the phone number is correct",
    ts_emailError: "Make sure the email is correct",
    ts_qesSubmittSuccess: "Your QES information has been submitted successfully",
    ts_qesEmailNotFound: "Your email is not found in the system, please fill in your name and your last name",
    ts_qesError: "Failed to update your QES information",
    ts_because: "because",
    ts_qesErrorSession: "Failed to check QES session",
    ts_success: "Success",
    ts_countryCodeError: "The recived country code is incorrect",
    ts_qesNameNotFound: "Name or last name can not be empty",
    ts_longWaitTimeTitle: "Longer then usual wait times",
    ts_longWaitTimeText1: "Sorry for the long wait! Unfortunately, it is taking longer than expected to process your request. It could be that your request has already been processed and sent to the correct recipient. In this case, please check if you have received a submission receipt from PrivaSphere. This submission receipt is a legal confirmation that your form has been submitted.",
    ts_longWaitTimeText2: "If you have received a deposit receipt but the form page shows that it is still submitting, please notify us of this bug via: ",
    ts_includeLogs: "Please download the logs and include them in the email as an attachment.",
    ts_downloadLogs: "Download Logs",
    ts_inputNumber: "Input number",
    ts_inputAmendedWithProperDots: "The input was amended with proper dots",
    ts_RemovedNonLetters: "Non alphabetic characters have been removed.",
    ts_error: "Error",
    ts_whiteSpacesAtStartEndRemoved: "Whitespaces at the beginning and end have been removed.",
    ts_InvalidPhoneNumber: "Phone number does not follow the correct format, please correct it.",
    ts_numberIsTooLow: "The input is lower than the set minimum '{0}'.",
    ts_secondsElapsedWaitPanel: "Elapsed:",
    ts_numberIsTooHigh: "The input is higher then the set maximum '{0}'.",
    ts_showRegexForUser: "The regex is '{0}'. If you are having issues, please contact {1} by taking a screenshot of this error, including the form name and the browser logs in the email.",
    ts_formSupportDialogTitle: "Request support",
    ts_formSupportDialogBody: "If you are having difficulties regarding the form or if you think there is an error in the form, please Email Support and explain your problem.",
    sd_supportButtonInForm: constVarLan["sd_supportButtonInForm"],
    ts_numberOutOfRange: "The input {0} is out of range. The ",
    ts_minAllowed: "minimum allowed is {0}",
    ts_maxAllowed: "maximum allowed is {0}",
};
